// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v012',
  USERDATA_KEY: 'auth1234567890',
  isMockEnabled: true,
  baseUrl: 'https://betatest.childcarephotos.com.au',
  apiUrl: 'https://apitest.childcarephotos.com.au',
prodHttp: "https://apitest.childcarephotos.com.au/uploads/product_images/",
childHttp: "https://apitest.childcarephotos.com.au/uploads/photos/",
advHttp:"https://apitest.childcarephotos.com.au/uploads/advertisements/",
  // apiUrl: 'http://ccphotos.dev7.wpg.ua/api'
packageHttp : "https://betatest.childcarephotos.com.au/dashboard/client/store/packege/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
